import * as React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Heading,
  Text,
  VStack,
  Link as ChakraLink,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import Layout from '../layout';
import Head from '../components/head';
import { RequestShippingQuote } from '../components/forms';
import ShippingFAQs from '../components/faqs/shipping';

const Shipping: React.FC = () => (
  <Layout headerColorScheme="dark">
    <Head title="Shipping - Jamestown" />

    <Box
      p="6rem calc(var(--cp) + 1rem)"
      as="section"
      position="relative"
      _before={{
        content: '" "',
        width: '100%',
        top: 0,
        left: 0,
        height: '500px',
        position: 'absolute',
        bg: 'purple',
        zIndex: 'base',
      }}
    >
      <Grid templateColumns={{ md: '1fr 400px', sm: '', base: '' }} gap={5}>
        <VStack
          zIndex="docked"
          color="white"
          align="left"
          maxW="100%"
          spacing={5}
          as={GridItem}
        >
          <Heading size="2xl">
            Ship parcels from one location to another at ease.
          </Heading>

          <Text fontWeight={500}>
            For those who love shipping items without worries. Ship with
            Jamestown, delivery without hassle.
          </Text>
        </VStack>

        <Box
          as={VStack}
          zIndex="docked"
          mt={{ md: 0, base: 10 }}
          bg="white"
          boxShadow="sm"
          p={10}
          ml={{ md: 10, base: 0 }}
          maxWidth="100%"
          borderRadius="lg"
        >
          <VStack align="left" spacing={10}>
            <Heading size="lg" color="purple">
              Ship an Item
            </Heading>

            <RequestShippingQuote />
          </VStack>
        </Box>
      </Grid>
    </Box>

    <Box
      bg="#eee"
      py="6rem"
      px={{ lg: 'calc(var(--cp) + 10rem)', base: 5 }}
      textAlign="center"
    >
      <VStack
        p="0 5px"
        width="500px"
        maxW="100%"
        sx={{ margin: '0 auto !important' }}
      >
        <Heading size="lg" color="purple">
          Frequently Asked Questions (FAQs)
        </Heading>
        <Text fontWeight={500}>
          Our focus is always on finding the best people to work with.
        </Text>
      </VStack>

      <Box my={10}>
        <ShippingFAQs />
      </Box>

      <Text fontWeight={500}>
        How can we help?{' '}
        <ChakraLink to="/contact" href="/contact" as={Link}>
          Contact us
        </ChakraLink>
      </Text>
    </Box>
  </Layout>
);

export default Shipping;
