/* eslint-disable react/no-array-index-key */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from '@chakra-ui/react';
import React from 'react';
import FAQs from './contents/shipping.json';

const Shipping: React.FC = () => (
  <Accordion allowToggle>
    {FAQs.map((faq, index) => (
      <AccordionItem key={index}>
        <Heading size="md">
          <AccordionButton py={3}>
            <Box flex="1" textAlign="left" fontWeight={500}>
              {faq.question}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel
          pb={4}
          textAlign="left"
          dangerouslySetInnerHTML={{ __html: faq.answer as string }}
        />
      </AccordionItem>
    ))}
  </Accordion>
);

export default Shipping;
